<template>
  <form
    role="form"
    class="text-start mt-3"
    @submit.prevent="submit"
  >
    <template v-if="appUserId && emailAddress.length">
      <p class="mt-4 text-sm">
        Geef a.u.b. een wachtwoord in van minimaal 8 tekens lang met minimaal 1 hoofdletter, 1
        kleine letter, 1 cijfer en 1 leesteken.
      </p>

      <material-alert
        v-show="error.length"
        color="danger"
        outline
      >
        {{ error }}
      </material-alert>

      <material-input
        id="new-password"
        v-model="newPassword"
        autocomplete="new-password"
        class="mb-4"
        type="password"
        :disabled="loading"
        label="Wachtwoord"
        is-required
      />

      <material-input
        id="new-password-confirm"
        v-model="newPasswordConfirm"
        autocomplete="new-password"
        type="password"
        :disabled="loading"
        label="Wachtwoord nogmaals"
        is-required
      />

      <material-button
        class="my-4"
        :disabled="loading"
        variant="gradient"
        color="primary"
        full-width
      >
        Opslaan
      </material-button>
    </template>

    <material-alert
      v-else-if="error.length"
      color="danger"
      outline
    >
      {{ error }}
    </material-alert>
  </form>
</template>

<script>
import { setPassword, verifySetPassword } from '@/api/providers/app';
import MaterialAlert from "@/components/MaterialAlert.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";

export default {
  name: "AppPassword",

  components: {
    MaterialAlert,
    MaterialButton,
    MaterialInput,
  },

  data: () => ({
    appUserId: 0,
    emailAddress: '',
    error: '',
    loading: false,
    newPassword: '',
    newPasswordConfirm: '',
  }),

  created() {
    const { token, type } = this.$route.params;

    verifySetPassword(type, token)
      .then(({ appUserId, emailAddress }) => {
        this.appUserId = appUserId;
        this.emailAddress = emailAddress;
      })
      .catch(({ message }) => {
        this.error = message;
      });
  },

  methods: {
    submit() {
      if (this.loading) {
        return;
      }

      const { token, type } = this.$route.params;
      this.loading = true;

      setPassword(
        type,
        token,
        this.appUserId,
        this.emailAddress,
        this.newPassword,
        this.newPasswordConfirm,
      )
        .then(() => {
          this.$router.push({
            name: 'app-password-success',
          });
        })
        .catch(({ message }) => {
          this.error = message;
        })
        .then(() => {
          this.loading = false;
        });
    },
  },
};
</script>
