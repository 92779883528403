import { getRequest, postRequest } from '..';

export function setPassword(
  type,
  token,
  appUserId,
  emailAddress,
  newPassword,
  repeatPassword,
) {
  return postRequest(
    `/account/password/${type}`,
    {
      token,
      appUserId,
      emailAddress,
      newPassword,
      repeatPassword,
    },
    false,
  );
}

export function verifySetPassword(type, token) {
  return getRequest(
    `/account/password/${type}?token=${token}`,
    false,
  );
}
